import Background from './background.svg';
import './App.css';

import { useEffect, useState } from 'react';

function App() {
  // Opening animation
  const [OnLoadStyle, SetOnLoadStyle] = useState({marginTop: '1vh', opacity: '0.5'})

  function Loaded() {
    console.log("worked")
    SetOnLoadStyle({});
  }

  useEffect(Loaded, []);

  // Features Title Logic
  const [FeaturesTitleText, SetFeaturesTitleText] = useState("$SENTIENCE is...");

  function ChangeFeaturesTitleText(text) {
    SetFeaturesTitleText(text);
  }

  async function slowChange(text) {
      for (let i = 0; i < text.length; i++) {
        await sleep(100); // Wait for 500ms
        ChangeFeaturesTitleText(prev => prev + text[i]); // Append the next character
      }
      ChangeFeaturesTitleText(prev => prev + "\n"); // Add a newline after each line
  }

  async function slowDelete(count) {
    for (let i = 0; i < count; i++) {
      await sleep(100); // Wait for 150ms between each deletion
      SetFeaturesTitleText(prev => prev.slice(0, -1)); // Remove one character from the end
    }
  }
  

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function Loop() {
    while (true) {
      await sleep(1000);
      for (let i = 0; i < 4; i++) {
        ChangeFeaturesTitleText("$SENTIENCE is|")
        await sleep(400);
        ChangeFeaturesTitleText("$SENTIENCE is")
        await sleep(400);
      }
      await slowChange(" an idea...")
      for (let i = 0; i < 4; i++) {
        ChangeFeaturesTitleText("$SENTIENCE is an idea...|")
        await sleep(400);
        ChangeFeaturesTitleText("$SENTIENCE is an idea...")
        await sleep(400);
      }

      await slowDelete(10);

      await sleep(100);
      for (let i = 0; i < 4; i++) {
        ChangeFeaturesTitleText("$SENTIENCE is|")
        await sleep(400);
        ChangeFeaturesTitleText("$SENTIENCE is")
        await sleep(400);
      }
      await slowChange(" a token...")
      for (let i = 0; i < 6; i++) {
        ChangeFeaturesTitleText("$SENTIENCE is a token...|")
        await sleep(400);
        ChangeFeaturesTitleText("$SENTIENCE is a token...")
        await sleep(400);
      }

      await await slowDelete(11);

      await sleep(100);
      for (let i = 0; i < 4; i++) {
        ChangeFeaturesTitleText("$SENTIENCE is|")
        await sleep(400);
        ChangeFeaturesTitleText("$SENTIENCE is")
        await sleep(400);
      }
      await slowChange(" artificial intelligence...")
      for (let i = 0; i < 6; i++) {
        ChangeFeaturesTitleText("$SENTIENCE is artificial intelligence...|")
        await sleep(400);
        ChangeFeaturesTitleText("$SENTIENCE is artificial intelligence...")
        await sleep(400);
      }

      await slowDelete(26);
    }
  }

  useEffect(() => {
    Loop()
  }, [])

  return (
    <>
      <div style={{backgroundImage: `url(${Background})`}} className='App'>

        <div className='Header'>
          <span className='Header-Title' style={OnLoadStyle}>$SENTIENCE</span>
          <div className='Header-Buttons'>
            <button onClick={() => {
              window.location.href = `https://x.com/sentiencetoken`;
            }} className='Header-Button' style={OnLoadStyle}>FOLLOW US</button>
            <button onClick={() => {
              document.getElementById('ca').scrollIntoView({behavior: 'smooth'})
            }} className='Header-Button Header-Coin-Address' style={OnLoadStyle}>COIN ADDRESS</button>
          </div>
        </div>

        <div className='Main'>

          <div className='Main-Title-Div' style={OnLoadStyle}>
            <span className='Main-Title Breathe'>
              An AI token to breathe <br></br> life into crypto.
            </span>
            <span id='ca' className='Coin-Address'>
            Coin Address: Unreleased
            </span>
          </div>

          <div className='Features'>
            <span className='Features-Title'>{FeaturesTitleText}</span>
            {/* <div className='Features-Container'>
              <div className='Features-Grid-A'>
                <div className='Feature'>

                </div>
                <div></div>
                <div className='Feature'>

                </div>
              </div>
              <div></div>
              <div className='Features-Grid-B'>
                <div></div>
                <div className='Feature'>
                  
                </div>
                <div></div>
              </div>
            </div> */}
          </div>
          
        </div>
      </div>
    </>
  );
}

export default App;
